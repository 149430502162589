


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LayoutMenuModule from "@/store/modules/layout-menu-module";

@Component({
  components: {
    UserProfile: () =>
      import("@/components/header/UserProfile/UserProfile.vue"),
  },
})
export default class Header extends Vue {
  @Prop() public title!: string;
  public get drawer(): boolean {
    return LayoutMenuModule.visible;
  }
  public set drawer(newtoggle: boolean) {
    LayoutMenuModule.setVisible(newtoggle);
  }
}
