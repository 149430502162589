import { Permission } from "@/shared/permission";
import { UtilsString } from "@/utils/utils-string";
import { MenuItem } from "./menu-item";
// Cada opción tendrá indicado:
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const MenuOptions: MenuItem[] = [
  {
    icon: "",
    icon_fntaws: "fad fa-tachometer-fast",
    text: "Inicio",
    color: "#673ab7",
    link: "/main/home",
    permission: UtilsString.Empty,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-users-cog",
    text: "Administración",
    color: "#E9BBA2",
    permission: Permission.read_ayuntamiento,
    link: "/main/administracion/",
    children: [
      {
        icon: "",
        icon_fntaws: "fad fa-users",
        text: "Usuarios",
        color: "#4C6EF5",
        permission: Permission.read_ayuntamiento,
        link: "/main/usuarios/lista",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-paper-plane",
        text: "Notificaciones",
        color: "#FD7E14",
        permission: Permission.read_admin,
        link: "/main/notificaciones/",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-home-lg-alt",
        text: "Ayuntamientos",
        color: "#F783AC",
        permission: Permission.read_admin,
        link: "/main/ayuntamientos/",
        children: [],
      },{
        icon: "",
        icon_fntaws: "fad fa-layer-group",
        text: "Agrupaciones",
        color: "#15AABF",
        permission: Permission.read_admin,
        link: "/main/agrupaciones/",
        children: [],
      },{
        icon: "",
        icon_fntaws: "fad fa-file-excel",
        text: "Importaciones",
        color: "#33C481",
        permission: Permission.read_admin,
        link: "/main/importaciones/",
        children: [],
      },
    ],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-cog",
    text: "Configuración",
    color: "#EBC8F4",
    permission: Permission.read_admin,
    link: "/main/configuracion/",
    children: [
      {
        icon: "",
        icon_fntaws: "fad fa-question-circle",
        text: "Encuestas",
        color: "#33C481",
        permission: Permission.read_admin,
        link: "/main/configuracion/preguntas/lista",
        children: [],
      },{
        icon: "",
        icon_fntaws: "fad fa-map-pin",
        text: "Mapeo de datos",
        color: "#DC6E6A",
        permission: Permission.read_admin,
        link: "/main/configuracion/mapeador/lista",
        children: [],
      },{
        icon: "",
        icon_fntaws: "fad fa-chart-pie",
        text: "Dashboards",
        color: "#DCCE80",
        permission: Permission.read_admin,
        link: "/main/configuracion/dashboards/lista",
        children: [],
      }
    ],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-sign-out-alt",
    text: "Cerrar sesión",
    permission: UtilsString.Empty,
    color: "#C6C6C5",
    link: "/main/cerrar_session",
    children: [],
  },
];
